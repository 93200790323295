import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "./components/Header";
import HeroSection from "./components/HeroSection";
import PartnerSection from "./components/PartnerSection";
import ServicePortfolio from "./components/ServicePortfolio";
import Footer from "./components/Footer";
import Impressum from "./components/Impressum";
import Dienstleistungen from "./components/Dienstleistungen";

function Home() {
  return (
    <>
      <Header />
      <HeroSection />
      <PartnerSection />
      <ServicePortfolio />

      <Footer />
    </>
  );
}

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/impressum" element={<Impressum />} />
        <Route path="/dienstleistungen" element={<Dienstleistungen />} />
      </Routes>
    </Router>
  );
}

export default App;
