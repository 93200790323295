import React from "react";
import { Link } from "react-router-dom";
import "../styles/Dienstleistungen.css";

function Dienstleistungen() {
  return (
    <div>
      <header className="header">
        <nav className="dnavigation">
          <ul>
            <li>
              <Link to="/" className="back-link">
                ← Zurück
              </Link>
            </li>
          </ul>
        </nav>
      </header>
      <main className="dienstleistungen-content">
        <h1>Dienstleistungen</h1>

        <div className="dienstleistung">
          <h2>Hotelreinigung</h2>
          <img
            src="/images/vacuum.webp"
            alt="Hotelreinigung"
            className="dienstleistung-bild"
          />
          <p>
            Wir legen großen Wert darauf, dass Ihr Hotel stets in bestem Glanz
            erstrahlt. Mit unserer sorgfältigen und diskreten Arbeitsweise
            sorgen wir dafür, dass Ihre Gäste in einer sauberen und einladenden
            Umgebung willkommen geheißen werden. Wir verwenden dabei innovative
            Technologien und umweltfreundliche Reinigungsmittel, um exzellente
            Resultate zu erzielen und gleichzeitig die Natur zu schützen.
          </p>
          <p>
            Unser Service passt sich flexibel Ihren Bedürfnissen an. Wir bieten
            individuell zugeschnittene Reinigungskonzepte, die sicherstellen,
            dass alle Bereiche Ihres Hotels kontinuierlich gepflegt und makellos
            bleiben.
          </p>
          <p>
            Profitieren Sie von unserer umfangreichen Erfahrung und
            Fachkompetenz in der Hotelreinigung. Kontaktieren Sie uns noch
            heute, um mehr über unser Angebot zu erfahren und gemeinsam die
            beste Lösung für Ihr Haus zu finden. Wir freuen uns auf eine
            erfolgreiche Zusammenarbeit und darauf, Ihr Hotel in neuem Glanz
            erstrahlen zu lassen.
          </p>
          <img
            src="/images/windowclean.webp"
            alt="Hotelreinigung Fensterreinigung"
            className="dienstleistung-bild"
          />
          <ul>
            <li>
              Gründliche Reinigung aller Hotelbereiche mit umweltfreundlichen
              Produkten
            </li>
            <li>
              Anwendung moderner Reinigungstechnologien für optimale Ergebnisse
            </li>
            <li>
              Anpassung der Reinigungspläne an die individuellen Anforderungen
              des Hotels
            </li>
            <li>
              Sicherstellung einer stets sauberen und einladenden Umgebung für
              Gäste
            </li>
            <li>
              Flexibilität bei der Planung und Durchführung der
              Reinigungsarbeiten
            </li>
            <li>
              Fokus auf Details für eine makellose Präsentation des Hotels
            </li>
            <li>
              Kontinuierliche Pflege und Instandhaltung aller Hotelbereiche
            </li>
            <li>
              Umweltbewusster Einsatz von Reinigungsmitteln und Ressourcen
            </li>
            <li>
              Regelmäßige Überprüfung der Sauberkeit und Hygiene im gesamten
              Hotel
            </li>
            <li>
              Unterstützung bei speziellen Reinigungs- und Dekorationsprojekten
            </li>
          </ul>
        </div>

        <div className="dienstleistung">
          <h2>Housekeeping</h2>
          <img
            src="/images/house.webp"
            alt="Housekeeping"
            className="dienstleistung-bild"
          />
          <p>
            Unsere engagierten Fachkräfte sorgen dafür, dass Ihr Hotel stets
            sauber, aufgeräumt und einladend ist. Wir legen großen Wert auf die
            kleinsten Details, um sicherzustellen, dass Ihre Gäste sich rundum
            wohlfühlen.
          </p>
          <p>
            Unsere Dienstleistungen sind flexibel und werden individuell an die
            Bedürfnisse und Wünsche Ihres Hauses angepasst. Egal ob tägliche
            Reinigung, Wäschepflege oder besondere Dekorationsprojekte – unser
            Team steht Ihnen zuverlässig zur Seite.
          </p>
          <p>
            Mit unserer umfassenden Erfahrung und Expertise im Housekeeping und
            Stewarding können Sie sich beruhigt auf das Wesentliche
            konzentrieren: das Wohl Ihrer Gäste. Wir kümmern uns um den Rest,
            damit der Betrieb reibungslos läuft und Sie den Hotelalltag
            entspannt genießen können.
          </p>
          <p>
            Nehmen Sie noch heute Kontakt mit uns auf, und lassen Sie uns
            gemeinsam dafür sorgen, dass sich Ihre Gäste rundum wohl und
            willkommen fühlen. Wir freuen uns auf eine erfolgreiche
            Zusammenarbeit!
          </p>
          <img
            src="/images/minibar.webp"
            alt="Housekeeping Minibar"
            className="dienstleistung-bild"
          />
          <ul>
            <li>Überprüfung der Gästezimmer auf Sauberkeit und Ordnung</li>
            <li>Organisation von Wäscherei und Reinigungsservices</li>
            <li>Koordination von Reparaturen und Instandhaltungsarbeiten</li>
            <li>Einarbeitung und Schulung neuer Housekeeping-Mitarbeiter</li>
            <li>Sicherstellung von Hygiene- und Qualitätsstandards</li>
            <li>Durchführung von Inventuren und Bestandsüberprüfungen</li>
            <li>
              Abstimmung von Reinigungs- und Wartungsarbeiten mit anderen
              Abteilungen
            </li>
            <li>Erstellung von Leistungsberichten für das Housekeeping-Team</li>
            <li>
              Umsetzung umweltfreundlicher Reinigungs- und Entsorgungsmaßnahmen
            </li>
            <li>
              Planung und Durchführung von Sonderprojekten zur Verbesserung der
              Servicequalität
            </li>
          </ul>
        </div>
      </main>
    </div>
  );
}

export default Dienstleistungen;
