import React from "react";
import "../styles/HeroSection.css";

function HeroSection() {
  return (
    <section className="hero-section">
      <div
        className="hero-background"
        style={{ backgroundImage: `url('/images/hero-image.png')` }}
      ></div>
      <div className="hero-content">
        <h1>Ihr zuverlässiger Dienstleister</h1>
      </div>
    </section>
  );
}

export default HeroSection;
