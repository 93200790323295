import React from "react";
import "../styles/PartnerSection.css";

function PartnerSection() {
  return (
    <section className="partner-section">
      <div className="partner-content">
        <h2>Ihr zuverlässiger Partner für Reinigung und Housekeeping</h2>
        <p>
          Qualität und Erfahrung sorgen bei Ihnen für reibungslose Abläufe und
          einen Top-Service für Ihre Gäste. Wir kümmern uns um die Reinigung der
          Zimmer und Gebäude, vom Treppenhaus bis zu den Fenstern, Böden, Gängen
          und Bädern – alles, was anfällt.
        </p>{" "}
        <p>
          Zusätzlich übernehmen wir gerne alle anderen Aspekte des
          Housekeepings, sei es das Bettenmachen, die Müllentsorgung oder andere
          Aufgaben, die Ihnen am Herzen liegen. Wir bieten Ihnen flexible und
          individuelle Teilaspekte, um die Arbeit Ihres Personals zu ergänzen
          oder vollständig zu übernehmen.{" "}
        </p>{" "}
        <p>
          Darüber hinaus sind wir auch Ihr verlässlicher Partner im Facility
          Management. Unser Team steht Ihnen bei der Instandhaltung und den
          Reparaturen aller Art zur Seite, ob es um die Reparatur von
          Klimaanlagen und Heizungen oder die Installation von Lampen und
          anderen technischen Einrichtungen geht.{" "}
        </p>{" "}
        <p>
          Unsere umfassenden Dienstleistungen garantieren, dass Ihre Anlage in
          einwandfreiem Zustand bleibt und Sie sich auf das Wesentliche
          konzentrieren können – Ihren Betrieb.
        </p>
      </div>
      <div className="partner-image">
        <img src="/images/partner-image.png" alt="Partner" />
      </div>
    </section>
  );
}

export default PartnerSection;
