import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../styles/Header.css";

function Header() {
  const [menuActive, setMenuActive] = useState(false);

  const toggleMenu = () => {
    console.log("Hamburger menu clicked!");
    setMenuActive(!menuActive);
    console.log("Menu active state:", !menuActive);
  };

  return (
    <header className="header">
      <div className="logo">
        <img src="/images/ajcslogo2.png" alt="Logo" />
      </div>
      <div className="hamburger" onClick={toggleMenu}>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <nav className={`navigation ${menuActive ? "active" : ""}`}>
        <ul>
          <li>
            <Link to="/dienstleistungen">Dienstleistungen</Link>
          </li>
          <li>
            <Link to="/impressum">Kontakt/Impressum</Link>
          </li>
        </ul>
      </nav>
    </header>
  );
}

export default Header;
