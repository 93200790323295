import React from "react";
import { Link } from "react-router-dom";
import "../styles/Impressum.css";

function Impressum() {
  return (
    <div className="impressum-container">
      <nav className="inavigation">
        <Link to="/" className="back-link">
          ← Zurück
        </Link>
      </nav>
      <main className="impressum-content">
        <h1>Impressum</h1>
        <p>
          Dieses Impressum gilt für alle Inhalte dieser Website. Wir bemühen
          uns, alle gesetzlichen Vorgaben einzuhalten und die Interessen unserer
          Nutzer zu schützen. Verantwortlich für den Inhalt dieser Website ist
          Singh Ajaypal. Falls Sie Fragen oder Anmerkungen zu unserer Website
          haben, kontaktieren Sie uns bitte.
        </p>
        <p>
          Singh Ajaypal
          <br />
          A&J Clean Services
          <br />
          Anschrift: Dünnwalder Str. 27, 51063 Köln
          <br />
          Telefon: 01739057278
          <br />
          E-Mail: AundJ.clean@gmail.com
          <br />
          Aufsichtsbehörde: BG Bau
        </p>
      </main>
    </div>
  );
}

export default Impressum;
