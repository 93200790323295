import React from "react";
import "../styles/ServicePortfolio.css";
import { Link } from "react-router-dom";

function ServicePortfolio() {
  return (
    <section className="service-portfolio">
      <h2>Unser Serviceportfolio</h2>
      <div className="services">
        <Link to="/dienstleistungen" className="service">
          <img src="/images/housekeep.png" alt="Service 1" />
          <p>Hotelreinigung</p>
        </Link>
        <Link to="/dienstleistungen" className="service">
          <img src="/images/steward.png" alt="Service 2" />
          <p>Housekeeping</p>
        </Link>
        {/* Weitere Services hier einfügen */}
      </div>
    </section>
  );
}

export default ServicePortfolio;
